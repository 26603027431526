import logo from "assets/images/brands/brand-main-sm.png";

export const clientName = "Exinitic";
export const developedBy = "Exinitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const crmUrl = process.env.REACT_APP_CP_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Exinitic";
export const sidebarLogo = logo;
export const mainLogo = logo;