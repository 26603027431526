import React, {
  useState, useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row, Col, Card, CardBody, CardHeader, CardTitle
} from "reactstrap";
import { Link } from "react-router-dom";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import { useTranslation } from "react-i18next";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import moment from "moment";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { fetchTransactionsStart } from "store/actions";

export default function GoldTransactions({ clientId = undefined, showSearch = false }) {
  const { t } = useTranslation();

  const { transactions = [], loading = false, transactionPagination } = useSelector((state) => state.goldReducer.transactions);
  const [searchInput, setSearchInput] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);

  const dispatch = useDispatch();

  const loadTransactions = (page, limit) => {
    dispatch(fetchTransactionsStart(
      { 
        page,
        limit,
        customerId: clientId,
      }
    ));
  };

  useEffect(() => {
    loadTransactions(1, sizePerPage);
  }, [sizePerPage]);

  const columns = [
    {
      dataField:"checkbox",
      text: <input type="checkbox" id = "check-all-deposits" onChange = {()=>checkAllBoxes("check-all-deposits", ".deposit-checkbox")}/>
    },
    {
      dataField: "createdAt",
      text: t("Date"),
      formatter: (val) => <>{
        moment(val.createdAt).format("DD/MM/YYYY, h:mm:ss a")
      }</>
    }, 
    {
      dataField:"customerId",
      text:t("Client"),
      formatter:(val)=>{
        if (val?.customerId?.firstName && val?.customerId?.lastName){
          return (
            <div>
              <Link 
                to ={{
                  pathname : `/clients/${val?.customerId?._id}/profile`,
                  state : { clientId : val?.customer }
                }}>
                <i className="no-italics">{val?.customerId ? `${captilazeFirstLetter(val?.customerId?.firstName)} ${captilazeFirstLetter(val?.customerId?.lastName)}` : ""}</i>
              </Link>
            </div>
          );
        } else {
          return (
            <div>
              <Link 
                to ={{
                  pathname : `/clients/${val?.customer?._id}/profile`,
                  state : { clientId : val?.customer }
                }}>
                <i className="no-italics">{val?.customer ? `${captilazeFirstLetter(val?.customer?.firstName)} ${captilazeFirstLetter(val?.customer?.lastName)}` : ""}</i>
              </Link>
            </div>
          );
        }
      }
    },
    {
      dataField: "type",
      text: t("Order Type"),
      formatter: (val) => (
        <>{val?.type}</>
      )
    },
    {
      dataField: "status",
      text: t("Status"),
      formatter: (val) => (
        <>{val?.status}</>
      )
    },
    {
      dataField:"amount",
      text:t("Amount"),
      formatter: (val) => (val?.amount?.$numberDecimal || val?.amount || "-"),
    },
    {
      dataField: "strategyId",
      text: t("Product Name"),
      formatter: (val) => (
        <>{val?.goldId?.title || val?.products?.title || "-"}</>
      )
    },
    {
      dataField: "strategyId",
      text: t("Product Type"),
      formatter: (val) => (
        <>{val?.assets?.symbol  || "-"}</>
      )
    },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle>{t(`Orders - (${transactions.length})`)}</CardTitle>
              </div>
              {
                showSearch && (
                  <div className="d-flex justify-content-between align-items-center">
                    <SearchBar handleSearchInput={handleSearchInput} placeholder={t("Search for Transactions")}/>
                  </div>
                )
              }
            </CardHeader>
            <CardBody>
              
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table  table-hover "
                  >
                    <Thead className="text-center table-light" >
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}>{column.text}</Th>
                        )}
                      </Tr>
                    </Thead>
                    {
                      transactions.length === 0 
                        ?
                        <Tbody style = {{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}                            
                          {!loading &&
                            <>
                              <Tr>
                                <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                  <h3 className="fw-bolder text-center">No records</h3>
                                </Td>
                              </Tr>
                            </>
                          }
                        </Tbody>
                        :
                        <Tbody style = {{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {console.log(transactions)}
                          {!loading && transactions.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`} className={`pt-4 ${column.dataField === "dropdown" && "d-flex justify-content-center"}`}>
                                  { column.dataField === "checkbox" ? <input className = "deposit-checkbox" type="checkbox"/> : ""}
                                  { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                    }
                  </Table>
                  <CustomPagination
                    {...transactionPagination}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadTransactions}
                    docs={transactions.filter(t => {
                      if (!clientId) return true;
                      return t.customerId._id === clientId;
                    })}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
