import React, { useState } from "react";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { MetaTags } from "react-meta-tags";
import classNames from "classnames";
import FxReports from "./FxReports";
import ProductReports from "./Products/ProductReports";

function ReportsList() {
  const tabs = [
    {
      tabId: "1",
      navLinkName: "Forex",
      component: <FxReports />,
    },
    {
      tabId: "2",
      navLinkName: "Products",
      component: <ProductReports />,
    },
  ];

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => activeTab !== tab && setActiveTab(tab);

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Reports
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Reports</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle>
                    Reports
                  </CardTitle>
                </CardHeader>
                <CardBody >
                  <Nav tabs>
                    {tabs.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => toggle(tabItem.tabId)}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted" style={{
                    minHeight: "calc(100vh - 200px)"
                  }}>
                    {tabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">{tabItem.component}</Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default (ReportsList);
